import React, { useState, useEffect, useCallback } from "react";

const BJCPStyleSearch = () => {
  const [searchTerm, setSearchTerm] = useState("");
  const [searchResults, setSearchResults] = useState([]);
  const [beerDetails, setBeerDetails] = useState(null);
  const [jsonData, setJsonData] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchJSONData = useCallback(async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://raw.githubusercontent.com/BrewVault/bjcp-json/main/data.json"
      );
      const data = await response.json();
      setJsonData(data);
      setIsLoading(false);
      return data;
    } catch (error) {
      console.error("Error fetching JSON data:", error);
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchJSONData();
  }, [fetchJSONData]);

  const searchStyles = (term) => {
    if (term) {
      const matchingBeers = jsonData.filter((beer) =>
        Object.values(beer).some(
          (value) =>
            typeof value === "string" &&
            value.toLowerCase().includes(term.toLowerCase())
        )
      );
      setSearchResults(matchingBeers);
    } else {
      setSearchResults([]);
    }
  };

  const displayBeerDetailsFromSearch = (beer) => {
    setBeerDetails(beer);
    setSearchResults([]);
  };

  const highlightText = (text, term) => {
    if (!term) return text;
    const regex = new RegExp(`(${term})`, "gi");
    return text.replace(regex, '<span class="highlight">$1</span>');
  };

  const safeGet = (obj, key, defaultValue = "-") => {
    return obj[key] !== undefined && obj[key] !== null
      ? obj[key]
      : defaultValue;
  };

  return (
    <div className="container">
      <h2>BJCP Style Searcher</h2>
      {isLoading ? (
        <p>Loading data...</p>
      ) : (
        <>
          <div className="search-container">
            <label htmlFor="search">Search Styles:</label>
            <div className="input-container">
              <input
                type="text"
                id="search"
                value={searchTerm}
                onChange={(e) => {
                  setSearchTerm(e.target.value);
                  searchStyles(e.target.value);
                }}
                placeholder="Type to search..."
              />
              <span
                id="clear-btn"
                className="clear-btn"
                onClick={() => {
                  setSearchTerm("");
                  setSearchResults([]);
                }}
              >
                ×
              </span>
            </div>
            <div id="search-results" className="search-results">
              {searchResults.map((beer, index) => (
                <div
                  key={index}
                  className="search-result-item"
                  onClick={() => displayBeerDetailsFromSearch(beer)}
                  dangerouslySetInnerHTML={{
                    __html: `${safeGet(beer, "name")} (${safeGet(
                      beer,
                      "categorynumber"
                    )} - ${safeGet(beer, "number")})`,
                  }}
                ></div>
              ))}
            </div>
          </div>
          <div id="beer-details" className="beer-details">
            {beerDetails ? (
              <>
                <h2>{safeGet(beerDetails, "name")}</h2>
                <p>
                  <strong>Category:</strong> {safeGet(beerDetails, "category")}
                </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Overall Impression:</strong> ${highlightText(
                      safeGet(beerDetails, "overallimpression"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Aroma:</strong> ${highlightText(
                      safeGet(beerDetails, "aroma"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Appearance:</strong> ${highlightText(
                      safeGet(beerDetails, "appearance"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Flavor:</strong> ${highlightText(
                      safeGet(beerDetails, "flavor"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Mouthfeel:</strong> ${highlightText(
                      safeGet(beerDetails, "mouthfeel"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Comments:</strong> ${highlightText(
                      safeGet(beerDetails, "comments"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>History:</strong> ${highlightText(
                      safeGet(beerDetails, "history"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Characteristic Ingredients:</strong> ${highlightText(
                      safeGet(beerDetails, "characteristicingredients"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Style Comparison:</strong> ${highlightText(
                      safeGet(beerDetails, "stylecomparison"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p>
                  <strong>IBU:</strong> {safeGet(beerDetails, "ibumin", "-")} -{" "}
                  {safeGet(beerDetails, "ibumax", "-")}
                </p>
                <p>
                  <strong>OG:</strong> {safeGet(beerDetails, "ogmin", "-")} -{" "}
                  {safeGet(beerDetails, "ogmax", "-")}
                </p>
                <p>
                  <strong>FG:</strong> {safeGet(beerDetails, "fgmin", "-")} -{" "}
                  {safeGet(beerDetails, "fgmax", "-")}
                </p>
                <p>
                  <strong>ABV:</strong> {safeGet(beerDetails, "abvmin", "-")}% -{" "}
                  {safeGet(beerDetails, "abvmax", "-")}%
                </p>
                <p>
                  <strong>SRM:</strong> {safeGet(beerDetails, "srmmin", "-")} -{" "}
                  {safeGet(beerDetails, "srmmax", "-")}
                </p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Commercial Examples:</strong> ${highlightText(
                      safeGet(beerDetails, "commercialexamples"),
                      searchTerm
                    )}`,
                  }}
                ></p>
                <p
                  dangerouslySetInnerHTML={{
                    __html: `<strong>Tags:</strong> ${highlightText(
                      safeGet(beerDetails, "tags"),
                      searchTerm
                    )}`,
                  }}
                ></p>
              </>
            ) : (
              <p>Search for a beer styles in the search box above.</p>
            )}
          </div>
        </>
      )}
      <p>
        <a href="https://github.com/BrewVault/bjcp-json">GitHub JSON Source</a>
      </p>
      <br />
      <br />
    </div>
  );
};

export default BJCPStyleSearch;
