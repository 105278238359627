import React, { useEffect, useState } from "react";
import "./styles.css";

function Footer() {
  // Whether the page is short enough to show everything w/o scroll
  const [noScrollNeeded, setNoScrollNeeded] = useState(false);
  // Whether the user has reached the bottom (trigger fade)
  const [showFooter, setShowFooter] = useState(false);

  useEffect(() => {
    const docHeight = document.body.offsetHeight;
    const winHeight = window.innerHeight;

    // If the page content fits entirely on-screen, no scroll needed
    if (docHeight <= winHeight) {
      setNoScrollNeeded(true);
      // No scroll listener needed
      return;
    }

    // Otherwise, listen for scroll. Show footer when scrolled to bottom.
    function handleScroll() {
      const scrolledToBottom = window.innerHeight + window.scrollY >= docHeight;

      // If scrolled to bottom, reveal the footer
      if (scrolledToBottom) {
        setShowFooter(true);
      } else {
        setShowFooter(false);
      }
    }

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  // 1) If no scroll is needed, show a "normal" footer (no animation).
  if (noScrollNeeded) {
    return (
      <footer className="footer footer-static">
        <div class="float-container">
          <div class="float-child">
            <div className="social-icons">
              {/* Instagram, YouTube, X icons, etc. */}
              <a
                href="https://instagram.com/BrewVaultNet"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-instagram fa-2x"></i>
              </a>
              <a
                href="https://youtube.com/@BrewVaultNet"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fab fa-youtube fa-2x"></i>
              </a>
              <a
                href="https://x.com/BrewVaultNet"
                target="_blank"
                rel="noopener noreferrer"
              >
                <i className="fa-brands fa-x-twitter fa-2x"></i>
              </a>
            </div>
          </div>
          <div class="float-child">
            &copy; 2023-{new Date().getFullYear()} BrewVault.net
          </div>
        </div>
      </footer>
    );
  }

  // 2) If scroll is needed, show a pinned/fixed footer that slides/fades in at the bottom
  return (
    <footer
      className={`footer footer-fixed ${
        showFooter ? "footer-visible" : "footer-hidden"
      }`}
    >
      <div className="social-icons">
        {/* Instagram, YouTube, X icons, etc. */}
        <a
          href="https://instagram.com/BrewVaultNet"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-instagram fa-2x"></i>
        </a>
        <a
          href="https://youtube.com/@BrewVaultNet"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fab fa-youtube fa-2x"></i>
        </a>
        <a
          href="https://x.com/BrewVaultNet"
          target="_blank"
          rel="noopener noreferrer"
        >
          <i className="fa-brands fa-x-twitter fa-2x"></i>
        </a>
        <a href="https://brewvault.net" rel="noopener noreferrer">
          &copy; {new Date().getFullYear()} BrewVault.net
        </a>
      </div>
    </footer>
  );
}

export default Footer;
