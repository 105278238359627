import React, { useState, useEffect } from "react";
import Papa from "papaparse";
import "../styles.css";

function ReadingList() {
  const [books, setBooks] = useState([]);
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("All");

  useEffect(() => {
    // Fetch CSV file from the public folder
    fetch("/reading-list.csv")
      .then((response) => response.text())
      .then((csvText) => {
        const parsedData = Papa.parse(csvText, { header: true }).data;
        setBooks(parsedData);
      });
  }, []);

  // Extract unique categories
  const categories = ["All", ...new Set(books.map((book) => book.Category))];

  // Filter books based on search and category
  const filteredBooks = books.filter((book) => {
    const title = book.Title ? book.Title.toLowerCase() : ""; // Ensure no undefined error
    const matchesSearch = title.includes(searchQuery.toLowerCase());
    const matchesCategory =
      selectedCategory === "All" || book.Category === selectedCategory;
    return matchesSearch && matchesCategory;
  });

  // Group filtered books by category
  const groupedBooks = filteredBooks.reduce((acc, book) => {
    if (!acc[book.Category]) {
      acc[book.Category] = [];
    }
    acc[book.Category].push(book);
    return acc;
  }, {});

  return (
    <div className="container">
      <h2>Reading List</h2>
      <p>
        This list of books is curated to expand your brewing knowledge, covering
        everything from fundamentals to advanced techniques. Whether you're just
        starting out or refining your craft, these resources will deepen your
        understanding of ingredients, processes, and styles, helping you brew
        with more confidence and precision.
      </p>

      {/* Search & Filter Controls */}
      <div className="controls">
        <input
          type="text"
          placeholder="Search by title..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />

        <select
          value={selectedCategory}
          onChange={(e) => setSelectedCategory(e.target.value)}
        >
          {categories.map((category, index) => (
            <option key={index} value={category}>
              {category}
            </option>
          ))}
        </select>
      </div>

      {/* Display Books */}
      {Object.entries(groupedBooks).length > 0 ? (
        Object.entries(groupedBooks).map(([category, books]) => (
          <div key={category}>
            <h3>{category}</h3>
            <ul>
              {books.map((book, index) => (
                <li key={index}>
                  <a href={book.URL} target="_blank" rel="noopener noreferrer">
                    {book.Title}
                  </a>
                </li>
              ))}
            </ul>
          </div>
        ))
      ) : (
        <p>No books found.</p>
      )}
      <br />
      <br />
    </div>
  );
}

export default ReadingList;
