import React, { useState, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import "./styles.css";
import Header from "./Header";
import Account from "./accounts/Account";
import Login from "./accounts/Login";
import Register from "./accounts/Register";
import ForgotPassword from "./accounts/ForgotPassword";
import Home from "./Home";
import BJCPStyleSearch from "./tools/BJCPStyleSearch";
import DataFetcher from "./tools/DataFetcher";
import ColourConverter from "./tools/ColourConverter";
import PressureCalculator from "./tools/PressureCalculator";
import AlkalinityConverter from "./tools/AlkalinityConverter";
import ReadingList from "./tools/ReadingList";
import Checkout from "./payment/Checkout";
import MeadBackSweetening from "./tools/MeadBackSweetening";
import Footer from "./footer";
import PrivateRoute from "./components/PrivateRoute";

import "@fortawesome/fontawesome-free/css/all.min.css";
import { getCurrentUser } from "./services/AuthService";

function App() {
  const [isLoggedIn, setIsLoggedIn] = useState(() => !!getCurrentUser());

  useEffect(() => {
    const user = getCurrentUser();
    if (user && !isLoggedIn) {
      setIsLoggedIn(true);
    }
  }, [isLoggedIn]);

  const appStyle = {
    backgroundImage: `url(${process.env.PUBLIC_URL}/bg.webp)`,
    backgroundRepeat: "repeat",
    backgroundSize: "auto",
    minHeight: "100vh", // Ensures background covers the whole page
  };

  return (
    <Router>
      <div style={appStyle}>
        <Header isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
        <div className="container">
          <Routes>
            <Route path="/" element={<Home isLoggedIn={isLoggedIn} />} />
            <Route
              path="/login"
              element={<Login setIsLoggedIn={setIsLoggedIn} />}
            />
            <Route path="/register" element={<Register />} />
            <Route path="/forgot-password" element={<ForgotPassword />} />

            {/* Private Routes (Require Login) */}
            <Route
              path="/bjcpstylesearch"
              element={
                <PrivateRoute
                  element={<BJCPStyleSearch />}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
            <Route
              path="/data-fetcher"
              element={
                <PrivateRoute
                  element={<DataFetcher />}
                  isLoggedIn={isLoggedIn}
                />
              }
            />
            <Route
              path="/checkout"
              element={
                <PrivateRoute element={<Checkout />} isLoggedIn={isLoggedIn} />
              }
            />

            <Route path="/colourconverter" element={<ColourConverter />} />
            <Route
              path="/alkalinityconverter"
              element={<AlkalinityConverter />}
            />
            <Route path="/readinglist" element={<ReadingList />} />
            <Route
              path="/meadbacksweetening"
              element={<MeadBackSweetening />}
            />
            <Route
              path="/pressurecalculator"
              element={<PressureCalculator />}
            />

            {/* Account Page (Redirects to login if not logged in) */}
            <Route
              path="/account"
              element={isLoggedIn ? <Account /> : <Navigate to="/login" />}
            />
          </Routes>
        </div>
      </div>
      <Footer />
    </Router>
  );
}

export default App;
