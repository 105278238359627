import React, { useState, useEffect } from "react";
import {
  CognitoUserPool,
  CognitoUserAttribute,
} from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_APP_CLIENT_ID,
});

function Account() {
  const [email, setEmail] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [oldPassword, setOldPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [bfUsername, setBfUsername] = useState("");
  const [bfPassword, setBfPassword] = useState("");
  const [expiryDate, setExpiryDate] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          console.error("Error getting user session:", err);
          return;
        }
        user.getUserAttributes((err, attributes) => {
          if (err) {
            console.error("Error getting user attributes:", err);
            return;
          }
          attributes.forEach((attribute) => {
            if (attribute.Name === "email") {
              setEmail(attribute.Value);
              setNewEmail(attribute.Value);
            }
            if (attribute.Name === "custom:BFUsername") {
              setBfUsername(attribute.Value);
              process.env.REACT_APP_API_USERNAME = attribute.Value;
            }
            if (attribute.Name === "custom:BFPassword") {
              setBfPassword(attribute.Value);
              process.env.REACT_APP_API_PASSWORD = attribute.Value;
            }
            if (attribute.Name === "custom:expiry_date") {
              setExpiryDate(attribute.Value);
            }
          });
        });
      });
    }
  }, []);

  const maskPassword = (password) => {
    const startLength = 5;
    const endLength = 5;
    if (password.length <= startLength + endLength) {
      return password;
    }
    const maskedPart = "*".repeat(password.length - startLength - endLength);
    return (
      password.substring(0, startLength) +
      maskedPart +
      password.substring(password.length - endLength)
    );
  };

  const handleUpdateEmail = (e) => {
    e.preventDefault();
    const user = userPool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          setMessage(`Failed to get user session: ${err.message}`);
          return;
        }
        const attributeEmail = new CognitoUserAttribute({
          Name: "email",
          Value: newEmail,
        });
        user.updateAttributes([attributeEmail], (err) => {
          if (err) {
            setMessage(`Failed to update email: ${err.message}`);
          } else {
            setMessage("Email updated successfully.");
            setEmail(newEmail);
          }
        });
      });
    }
  };

  const handleUpdatePassword = (e) => {
    e.preventDefault();
    const user = userPool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          setMessage(`Failed to get user session: ${err.message}`);
          return;
        }
        user.changePassword(oldPassword, newPassword, (err, result) => {
          if (err) {
            setMessage(`Failed to update password: ${err.message}`);
          } else {
            setMessage("Password updated successfully.");
          }
        });
      });
    }
  };

  const handleUpdateAttributes = (e) => {
    e.preventDefault();
    const user = userPool.getCurrentUser();
    if (user) {
      user.getSession((err, session) => {
        if (err) {
          setMessage(`Failed to get user session: ${err.message}`);
          return;
        }
        const attributes = [
          { Name: "custom:BFUsername", Value: bfUsername },
          { Name: "custom:BFPassword", Value: bfPassword },
        ];
        user.updateAttributes(attributes, (err) => {
          if (err) {
            setMessage(`Failed to update attributes: ${err.message}`);
          } else {
            setMessage("Attributes updated successfully.");
            process.env.REACT_APP_API_USERNAME = bfUsername;
            process.env.REACT_APP_API_PASSWORD = bfPassword;
          }
        });
      });
    }
  };

  return (
    <div className="container">
      <h2>Account Settings</h2>
      {message && <p>{message}</p>}

      <form onSubmit={handleUpdateEmail}>
        <div className="form-group">
          <label>Current Email:</label>
          <p>{email}</p>
          <label>New Email:</label>
          <input
            type="email"
            value={newEmail}
            onChange={(e) => setNewEmail(e.target.value)}
          />
        </div>
        <button type="submit">Update Email</button>
      </form>

      <form onSubmit={handleUpdatePassword}>
        <div className="form-group">
          <label>Old Password:</label>
          <input
            type="password"
            value={oldPassword}
            onChange={(e) => setOldPassword(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>New Password:</label>
          <input
            type="password"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </div>
        <button type="submit">Update Password</button>
      </form>

      <form onSubmit={handleUpdateAttributes} style={{ paddingTop: "15px" }}>
        <div className="form-group">
          <label>Brewfather API Username:</label>
          <input
            type="text"
            value={bfUsername}
            onChange={(e) => setBfUsername(e.target.value)}
          />
        </div>
        <div className="form-group">
          <label>Brewfather API Password:</label>
          <input
            type="text"
            value={maskPassword(bfPassword)}
            onChange={(e) => setBfPassword(e.target.value)}
          />
        </div>
        <button type="submit">Update Custom Attributes</button>
      </form>

      <div className="form-group" style={{ paddingTop: "15px" }}>
        <label>Account Expiry Date:</label>
        <p>{expiryDate ? expiryDate : "Not set"}</p>
        {/*<stripe-buy-button
          buy-button-id="buy_btn_1PW9jH01rQVEOA8x75SbZoyH"
          publishable-key="pk_live_51PTiTb01rQVEOA8x9j93JyNTMejjwo4cvlOR029RmpTQI99DhJBOqtNvtdv3GSrbwOX6mZp4IxlbmsZUndE4ePWm001V1T4m9r"
        ></stripe-buy-button>*/}
      </div>
      <br />
    </div>
  );
}

export default Account;
