import React, { useState } from "react";

const AlkalinityConverter = () => {
  const [alkalinity, setAlkalinity] = useState("");
  const [bicarbonate, setBicarbonate] = useState("");

  const handleInputChange = (event) => {
    const input = event.target.value;

    // Update alkalinity value
    setAlkalinity(input);

    // Calculate and update bicarbonate value if input is valid
    if (input === "" || isNaN(input)) {
      setBicarbonate("");
    } else {
      const value = parseFloat(input) * 1.22; // Conversion factor
      setBicarbonate(value.toFixed(2));
    }
  };

  return (
    <div className="container">
      <h1>Alkalinity to Bicarbonate Converter</h1>
      <label htmlFor="alkalinityInput">Enter Alkalinity (mg/L as CaCO₃):</label>
      <input
        type="number"
        id="alkalinityInput"
        placeholder="e.g., 30"
        value={alkalinity}
        onChange={handleInputChange}
        className="input-field"
      />
      <div className="output">
        {bicarbonate && <p>Bicarbonate (HCO₃⁻): {bicarbonate} mg/L</p>}
      </div>
    </div>
  );
};

export default AlkalinityConverter;
