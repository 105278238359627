import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./styles.css";
import { CognitoUserPool } from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_APP_CLIENT_ID,
});

function Header({ isLoggedIn, setIsLoggedIn }) {
  const [navOpen, setNavOpen] = useState(false);
  const [toolsOpen, setToolsOpen] = useState(false); // Controls mobile toggle
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  const handleLogout = () => {
    const user = userPool.getCurrentUser();
    if (user) {
      user.signOut();
      setIsLoggedIn(false);
    }
  };

  const toggleNav = () => {
    setNavOpen(!navOpen);
  };

  const toggleTools = () => {
    if (isMobile) {
      setToolsOpen(!toolsOpen); // Only allow toggling in mobile view
    }
  };

  const closeNav = () => {
    setNavOpen(false);
    setToolsOpen(false); // Close all menus when navigation closes
  };

  return (
    <header>
      <h1>
        <Link
          to="/"
          style={{
            textDecoration: "none",
            whiteSpace: "nowrap",
            display: "inline-flex",
            alignItems: "center",
            color: "#fff",
          }}
        >
          BrewVault
          <img
            src={`${process.env.PUBLIC_URL}/favicon.ico`}
            alt="BrewVault Icon"
            style={{ width: "20px", height: "20px", marginLeft: "8px" }}
          />
        </Link>
      </h1>
      <nav>
        <div className="hamburger" onClick={toggleNav}>
          <span></span>
          <span></span>
          <span></span>
        </div>
        <div className={`nav-links ${navOpen ? "active" : ""}`}>
          <span className="close-btn" onClick={closeNav}>
            ×
          </span>
          <Link to="/" onClick={closeNav}>
            Home
          </Link>
          <Link to="/bjcpstylesearch" onClick={closeNav}>
            BJCP Search
          </Link>
          {/* Tools Submenu */}
          <div
            className="submenu"
            onMouseEnter={!isMobile ? toggleTools : undefined}
            onMouseLeave={!isMobile ? toggleTools : undefined}
          >
            {/* Submenu Trigger */}
            <div
              className="submenu-trigger"
              onClick={isMobile ? toggleTools : undefined}
            >
              <div className="submenu-title">
                Tools <span className="arrow">{toolsOpen ? "▲" : "▼"}</span>
              </div>
            </div>
            {/* Submenu Content */}
            <div
              className={`submenu-content ${
                isMobile && toolsOpen ? "open" : ""
              }`}
            >
              <Link to="/colourconverter" onClick={closeNav}>
                Colour Converter
              </Link>
              <Link to="/pressurecalculator" onClick={closeNav}>
                Pressure Calculator
              </Link>
              <Link to="/meadbacksweetening" onClick={closeNav}>
                Mead Back Sweetening
              </Link>
              <Link to="/alkalinityconverter" onClick={closeNav}>
                Alkalinity Converter
              </Link>
            </div>
          </div>
          <Link to="/readinglist" onClick={closeNav}>
            Reading List
          </Link>

          {isLoggedIn ? (
            <>
              <Link to="/account" onClick={closeNav}>
                Account
              </Link>
              <div
                className="submenu"
                onMouseEnter={!isMobile ? toggleTools : undefined}
                onMouseLeave={!isMobile ? toggleTools : undefined}
              >
                {/* Submenu Trigger */}
                <div
                  className="submenu-trigger"
                  onClick={isMobile ? toggleTools : undefined}
                >
                  <div className="submenu-title">
                    Premium Tools{" "}
                    <span className="arrow">{toolsOpen ? "▲" : "▼"}</span>
                  </div>
                </div>
                {/* Submenu Content */}
                <div
                  className={`submenu-content ${
                    isMobile && toolsOpen ? "open" : ""
                  }`}
                >
                  <Link to="/alkalinityconverter" onClick={closeNav}>
                    Alkalinity Converter
                  </Link>
                </div>
              </div>
              <Link to="/data-fetcher" onClick={closeNav}>
                Data Fetcher
              </Link>
              <button
                onClick={() => {
                  handleLogout();
                  closeNav();
                }}
              >
                Logout
              </button>
            </>
          ) : (
            <Link to="/login" onClick={closeNav}>
              Login
            </Link>
          )}
        </div>
      </nav>
    </header>
  );
}

export default Header;
