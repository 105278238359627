import { Navigate, useLocation } from "react-router-dom";

const PrivateRoute = ({ element, isLoggedIn }) => {
  const location = useLocation();

  return isLoggedIn ? (
    element
  ) : (
    <Navigate to={`/login?redirect=${encodeURIComponent(location.pathname)}`} />
  );
};

export default PrivateRoute;
