import React, { useState } from "react";
import { Helmet } from "react-helmet";
import "./styles.css";

function Home({ isLoggedIn }) {
  const [faqOpen, setFaqOpen] = useState(null);

  const handleFaqToggle = (index) => {
    setFaqOpen(faqOpen === index ? null : index);
  };

  const handleStartBrewingClick = () => {
    window.location.href = "/register";
  };

  return (
    <>
      <Helmet>
        <title>BrewVault - Brewing Tools and Tips</title>
        <meta
          name="description"
          content="BrewVault provides brewing tips, tools, and recipes for professional brewers and enthusiasts."
        />
        <meta
          name="keywords"
          content="beer brewing, professional brewing, home brewing, brewing tools, beer recipes"
        />
        <meta
          property="og:title"
          content="BrewVault - Brewing Tools and Tips"
        />
        <meta
          property="og:description"
          content="BrewVault provides brewing tips, tools, and recipes for brewers."
        />
        <meta property="og:image" content="link_to_image.jpg" />
        <meta property="og:url" content="https://BrewVault.net" />
      </Helmet>

      <div className="container">
        <div className="home-content">
          {/* Hero Section */}
          <div className="hero-section">
            <h2>
              BrewVault - Your Gateway to Professional Brewing Information
            </h2>
            <p>
              Explore tips, tools, and techniques for home and professional
              brewers alike.
            </p>

            {/* Hide button if logged in */}
            {!isLoggedIn && (
              <button className="cta-button" onClick={handleStartBrewingClick}>
                Start Using BrewVault Today
              </button>
            )}
          </div>

          {/* FAQ Section */}
          <section className="faq-section">
            <h3>Frequently Asked Questions</h3>
            {[
              {
                question: "What is BrewVault?",
                answer:
                  "BrewVault is a platform designed for both home and professional brewers to find tools, tips, and techniques to enhance their brewing process.",
              },
              {
                question: "How do I get started with brewing?",
                answer:
                  "Getting started is easy! You can sign up, browse our tutorials, and follow the brewing process step-by-step with the help of our tools and recipes.",
              },
              {
                question: "Is BrewVault free to use?",
                answer:
                  "Yes! BrewVault offers free access to most of its features. We do, however, offer premium services for advanced tools and features.",
              },
            ].map((faq, index) => (
              <div
                key={index}
                className={`faq-item ${faqOpen === index ? "open" : ""}`}
                onClick={() => handleFaqToggle(index)}
              >
                <div className="faq-question">
                  <h3>{faq.question}</h3>
                  <span className="faq-icon">
                    {faqOpen === index ? "−" : "+"}
                  </span>
                </div>
                {faqOpen === index && (
                  <div className="faq-answer">{faq.answer}</div>
                )}
              </div>
            ))}
          </section>
        </div>
      </div>
    </>
  );
}

export default Home;
