import React, { useState, useEffect, useCallback } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
} from "amazon-cognito-identity-js";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_APP_CLIENT_ID,
});

function Login({ setIsLoggedIn }) {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const redirectPath = searchParams.get("redirect") || "/";

  // Memoized login check to avoid re-renders
  const checkUserLogin = useCallback(() => {
    const user = userPool.getCurrentUser();
    if (user) {
      setIsLoggedIn(true);
      navigate(redirectPath, { replace: true });
    }
  }, [setIsLoggedIn, navigate, redirectPath]);

  useEffect(() => {
    checkUserLogin();
  }, [checkUserLogin]);

  const handleLogin = (e) => {
    e.preventDefault();
    const user = new CognitoUser({ Username: email, Pool: userPool });
    const authDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    user.authenticateUser(authDetails, {
      onSuccess: () => {
        setIsLoggedIn(true);
        navigate(redirectPath, { replace: true }); // Redirect back to the intended page
      },
      onFailure: (err) => {
        alert(`Login failed: ${err.message}`);
      },
    });
  };

  return (
    <div className="container">
      <h2>Login</h2>
      <form onSubmit={handleLogin}>
        <div className="form-group">
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit">Login</button>
      </form>
      <p>
        <a href="/forgot-password">Forgot your password?</a>
      </p>
      <p>
        Don't have an account? <a href="/register">Register</a>
      </p>
    </div>
  );
}

export default Login;
